import React, { useEffect, useState } from "react";

export default function Toast({
  toastData = {
    message: "",
    status: "error",
    isActive: false
  },
  dismiss
}) {
  const [internalIsActive, setInternalIsActive] = useState(toastData.isActive);

  useEffect(() => {
    if (toastData.isActive) {
      setInternalIsActive(true);
      setTimeout(() => {
        if (dismiss) {
          dismiss();
        } else {
          setInternalIsActive(false);
        }
      }, 4000);
    }
  }, [toastData.isActive]);

  return (
    <>
      {toastData.isActive && internalIsActive && (
        <div
          className={`z-50 w-426 fixed bottom-6 left-1/2 flex items-center justify-between px-4 space-x-2 rounded shadow-toast ${
            toastData.status === "success"
              ? "h-10 bg-complementary-success"
              : "h-12 bg-complementary-error"
          }`}
          style={{ marginLeft: "-213px" }}
        >
          <img
            src={
              toastData.status === "success"
                ? "/check-circle-success.svg"
                : "/check-circle-error.svg"
            }
          />
          <p className="flex-1 font-semibold text-xs text-white">
            {toastData.message}
          </p>
          <img
            src="/x-white.svg"
            className="cursor-pointer"
            onClick={dismiss}
          />
        </div>
      )}
    </>
  );
}
