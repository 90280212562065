import { Franchise } from "./franchise.serializer";

export class CoachSerializer {
  static deserializer(coach) {
    return {
      id: coach.id,
      coachType: coach.attributes.coach_type,
      createdAt: coach.attributes.created_at,
      email: coach.attributes.email,
      franchiseId: coach.attributes.franchise_id,
      name: coach.attributes.name,
      status: coach.attributes.status,
      statusChangedAt: coach.attributes.status_changed_at,
      updatedAt: coach.attributes.updated_at,
      franchise: Franchise.deserializer(coach.attributes.franchise)
    };
  }
}
