import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const Form = ({
  children,
  schema,
  onSubmitButton: OnSubmitButton,
  onCancelButton: OnCancelButton,
  onSubmit: callbackOnSubmit,
  onError: callbackOnError,
  initialValues,
  buttonsContainer: ButtonsContainer = ({ children }) => children,
  ...rest
}) => {
  const [customFormState, setCustomFormState] = useState({
    lastValues: initialValues,
    dirtyFields: null
  });

  const methods = useForm({
    defaultValues: initialValues,
    values: initialValues,
    resolver: yupResolver(schema),
    context: customFormState,
    reValidateMode: "onSubmit"
  });

  const onSubmit = data => {
    callbackOnSubmit(data, methods);
  };

  const onError = errors => {
    callbackOnError && callbackOnError(errors, methods);
  };

  useEffect(() => {
    setCustomFormState(prev => ({
      ...prev,
      dirtyFields: methods.formState.dirtyFields,
      lastValues: initialValues
    }));
  }, [methods.formState.dirtyFields, initialValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)} {...rest}>
        {children instanceof Function ? children(methods) : children}
        <ButtonsContainer>
          {OnSubmitButton && <OnCancelButton formMethods={methods} />}
          {OnCancelButton && <OnSubmitButton formMethods={methods} />}
        </ButtonsContainer>
      </form>
    </FormProvider>
  );
};
