import React from "react";

export const Modal = ({ isHidden, children }) => {
  return (
    !isHidden && (
      <div
        className="z-50 w-full h-screen fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center"
        style={{ background: "rgb(0 0 0 / 50%)" }}
      >
        {children}
      </div>
    )
  );
};
