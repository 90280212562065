import React from "react";
import { Field } from "../../../common/Field";
import { SELECT_PLACEHOLDERS } from "../../../utils/constants";
import { COACH_TYPES } from "../../../config/constants";
import { Select } from "../../../common/Select";
import { capitalizeWords } from "./../../../utils/capitalizeWords";
import { useFranchises } from "../../../franchises/hooks/useFranchises";

export const FieldsByStep = ({ step }) => {
  const franchises = useFranchises();

  switch (step) {
    case 1:
      return (
        <>
          <span className="text-xs font-bold">Contact information</span>
          <Field containerStyles="mt-6" name="coachName" label="Full Name" />
          <Field containerStyles="mt-4" name="coachEmail" label="Email" />
          <Select
            containerStyles="mb-6 mt-4"
            name="coachType"
            label="Coach type"
            hideRollbackButton
          >
            <option value={SELECT_PLACEHOLDERS.COACH_TYPE} disabled hidden>
              {SELECT_PLACEHOLDERS.COACH_TYPE}
            </option>
            {Object.entries(COACH_TYPES).map(([key, value]) => (
              <option value={value} key={key}>
                {capitalizeWords(key)}
              </option>
            ))}
          </Select>
          <Select
            containerStyles="mb-6 mt-4"
            name="franchiseId"
            label="Franchise"
            hideRollbackButton
          >
            <option value={SELECT_PLACEHOLDERS.FRANCHISE} disabled hidden>
              {SELECT_PLACEHOLDERS.FRANCHISE}
            </option>
            {franchises.map(franchise => (
              <option value={franchise.id} key={franchise.id}>
                {franchise.name}
              </option>
            ))}
          </Select>
        </>
      );
    case 2:
      return (
        <div>
          <span className="text-xs font-bold">Coach Login Credentials</span>
          <Field
            containerStyles="mt-4"
            name="userEmailCredential"
            label="User"
            disabled
          />
          <Field
            containerStyles="mt-4"
            name="userPasswordCredential"
            label="Password"
            disabled
          />
        </div>
      );
  }
};
