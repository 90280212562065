import React from "react";

export const Button = ({
  otherClases,
  style,
  text,
  disabled = false,
  ...rest
}) => {
  const styles =
    style === "darken" &&
    ` text-white cursor-pointer ${
      disabled
        ? "cursor-not-allowed bg-neutral-300"
        : "bg-neutral-500 hover:bg-neutral-300"
    }`;

  return (
    <button
      className={`w-full transition duration-200 ease-in-out p-4 rounded ${styles} ${
        otherClases ?? ""
      }`}
      {...rest}
    >
      {text}
    </button>
  );
};
