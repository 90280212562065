export const foodJournalCategories = [
  "Beef",
  "Poultry",
  "Seafood",
  "Pork",
  "Required Vegetables",
  "Fruits",
  "Bonus Vegetables",
  "Carbohydrates",
  "Other"
];

export const PHONE_REGEX = /^\d+$/;

export const FULL_NAME_REGEX = /^\s*[\S]+(\s[\S]+)+\s*$/gms;

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{1,}))$/;

export const ONLY_NUMBERS_REGEX = /^[0-9]+$/gi;

export const SELECT_PLACEHOLDERS = {
  COACH: "Select Health Coach",
  COACH_TYPE: "Select Coach Type",
  FRANCHISE: "Select Franchise"
};
