import { isBefore, parseISO } from "date-fns";

export const validationStartDate = (e, changeField, setError) => {
  const today = new Date().setHours(0, 0, 0, 0);

  const selectedStartDate = e.target.value;
  const isPastDate = isBefore(parseISO(selectedStartDate), today);

  changeField("startDate", e);

  if (selectedStartDate === "") {
    setError({
      field: "startDate",
      message: "Start date is required",
    });
    return;
  }

  if (isPastDate) {
    setError({ field: "startDate", message: "Start date can't be the past" });
    return;
  }

  setError({ field: "startDate", message: null });
};

export const validationProgramLength = (e, changeField, setError) => {
  const MIN_WEEKS = 3;
  const MAX_WEEKS = 24;
  const selectedProgramLength = e.target.value;

  changeField("length", e);

  if (selectedProgramLength === "") {
    setError({
      field: "length",
      message: "Program length is required",
    });
    return;
  }

  if (selectedProgramLength > MAX_WEEKS) {
    setError({
      field: "length",
      message: `The length can't be greater than ${MAX_WEEKS}`,
    });

    return;
  }

  if (selectedProgramLength < MIN_WEEKS) {
    setError({
      field: "length",
      message: `The length can't be less than ${MIN_WEEKS}`,
    });

    return;
  }
  setError({ field: "length", message: null });
};

export const validationBodySculpting = (e, changeField, setError) => {
  const MAX_NUMBER = 72;
  const selectedBodySculpting = e.target.value;

  changeField("bodySculpting", e);

  if (selectedBodySculpting === "") {
    setError({
      field: "bodySculpting",
      message: "Body sculpting is required",
    });
    return;
  }

  if (selectedBodySculpting > MAX_NUMBER) {
    setError({
      field: "bodySculpting",
      message: `The body sculpting can't be greater than ${MAX_NUMBER}`,
    });

    return;
  }

  setError({ field: "bodySculpting", message: null });
};

export const validationWeightPounds = (e, changeField, setError) => {
  const { name: field, value: selectedWeightPounds } = e.target;

  let label = field.split(/(?=[A-Z])/).join(" "); // Separate words camelcase
  label = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(); // Capitalize the first letter

  changeField(field, e);

  if (selectedWeightPounds === "") {
    setError({
      field,
      message: `${label} is required`,
    });
    return;
  }

  setError({ field, message: null });
};

export const validationWeightOunces = (e, changeField, setError) => {
  const MAX_NUMBER = 16;
  const { name: field, value: selectedWeightOunces } = e.target;

  let label = field.split(/(?=[A-Z])/).join(" "); // Separate words camelcase
  label = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(); // Capitalize the first letter

  changeField(field, e);

  if (selectedWeightOunces === "") {
    setError({
      field,
      message: `${label} is required`,
    });
    return;
  }

  if (selectedWeightOunces > MAX_NUMBER) {
    setError({
      field,
      message: `The ${label.toLowerCase()} can't be greater than ${MAX_NUMBER}`,
    });

    return;
  }

  setError({ field, message: null });
};

export const programValidations = {
  length: validationProgramLength,
  startDate: validationStartDate,
  bodySculpting: validationBodySculpting,
  startWeightPounds: validationWeightPounds,
  startWeightOunces: validationWeightOunces,
  goalWeightPounds: validationWeightPounds,
  goalWeightOunces: validationWeightOunces,
};
