import { useQuery } from "@tanstack/react-query";
import { ClientsController } from "../../../networking/controllers/clients-controller";

export const useClient = id => {
  const { data: client } = useQuery({
    queryKey: ["client", id],
    queryFn: () => ClientsController.fetchClient(id)
  });

  return client;
};
