import React, { useState } from "react";
import { useNavigate } from "../hooks/useNavigate";
import { Hamburger } from "../common/icons";

const NavBar = ({
  subtitle,
  children,
  enableHamburger = false,
  rightRender: RightRender,
  hamburgerOptions
}) => {
  const navigate = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const onClickHamburger = () => {
    setHamburgerOpen(true);
  };

  const onCloseHamburger = () => {
    setHamburgerOpen(false);
  };

  return (
    <>
      <nav
        className="sticky top-0 left-0 right-0 z-10 bg-white"
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)"
        }}
      >
        <div className="w-full flex items-center justify-between px-10 border-b-0.5 h-20">
          <div className="flex gap-10 items-center">
            <img
              className="w-40 h-8 cursor-pointer"
              src="/myinspire-logo.svg"
              onClick={() => navigate("/")}
            />
            <div>{subtitle}</div>
          </div>
          <div className="flex gap-4 items-center text-black">
            {enableHamburger ? (
              <button onClick={onClickHamburger}>
                <Hamburger className="w-4" />
              </button>
            ) : (
              <RightRender />
            )}
          </div>
        </div>
        {children}
      </nav>
      {enableHamburger && hamburgerOpen ? (
        hamburgerOptions(onCloseHamburger)
      ) : (
        <></>
      )}
    </>
  );
};

export default NavBar;
