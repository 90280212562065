import { FetchRequest } from "@rails/request.js";

export const api = async ({ method, endpoint, query, payload }) => {
  const request = new FetchRequest(method, endpoint.concat(".json"), {
    body: payload,
    contentType: "application/json",
    headers: {},
    query
  });

  const response = await request.perform();

  if (!response.ok) return Promise.reject(response);

  return response;
};
