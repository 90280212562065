import React, { useEffect, useState } from "react";
import DailyInputTabs from "./DailyInputTabs";
import DailyInputTable from "./DailyInputTable";
import Pagination from "../../common/Pagination";
import { useDailyInputs } from "../hooks/queries/useDailyInputs";
import { DailyInputsController } from "../../networking/controllers/daily-inputs-controller";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { Modal } from "../../common/Modal";

export default function ExpandedDailyInputsRecords({ client, program }) {
  const INITIAL_PAGE = 1;
  const INITIAL_TAB = "Weight";

  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const [selectedTab, setSelectedTab] = useState(INITIAL_TAB);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const dailyInputQuery = useDailyInputs({
    client_id: client.id,
    page: currentPage,
    program_id: program.id
  });

  useEffect(() => {
    setCurrentPage(INITIAL_PAGE);
    setSelectedTab(INITIAL_TAB);
  }, [client, program]);

  const updateDailyInputMutation = useMutation(
    ({ where, data }) =>
      DailyInputsController.updateDailyInput({ where, data }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries();
      }
    }
  );

  const onSubmitRow = async ({ row, data, message }) => {
    try {
      await toast.promise(
        updateDailyInputMutation.mutateAsync({
          where: {
            id: row.id,
            programId: row.programId,
            clientId: client.id
          },
          data
        }),
        message
      );
    } catch (error) {}
  };

  const setDisplayModal = show => {
    setShowModal(show);

    if (show) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  };

  return (
    <>
      <div className="w-full mt-6 px-10 py-4 bg-white rounded-lg shadow-container">
        <DailyInputTabs
          tabs={[
            "Weight",
            "Water Intake",
            "Food Journal",
            "Bowel Movement",
            "Hours of Sleep",
            "Notes"
          ]}
          selectedTab={selectedTab}
          onChangeTab={newTab => setSelectedTab(newTab)}
        />
        <DailyInputTable
          program={program}
          selectedTab={selectedTab}
          onSubmitRow={onSubmitRow}
          dailyInputQuery={dailyInputQuery}
          setShowModal={setDisplayModal}
          setModalContent={setModalContent}
        />
        <Pagination
          currentPage={currentPage}
          onChangePage={newPage => setCurrentPage(newPage)}
          totalPages={dailyInputQuery.data.totalPages}
        />
      </div>
      <Modal isHidden={!showModal && !modalContent}>{modalContent}</Modal>
    </>
  );
}
