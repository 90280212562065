import * as yup from "yup";
import { ONLY_NUMBERS_REGEX, foodJournalCategories } from "../utils/constants";

export const mealSchema = yup.object({
  category: yup
    .mixed()
    .notOneOf(["Category"], "Select a category")
    .oneOf(foodJournalCategories, "Invalid category"),
  specify: yup.string().required("Can't be empty"),
  ounces: yup
    .string()
    .matches(ONLY_NUMBERS_REGEX, "You must enter a number")
    .required("Can't be empty")
    .test(
      "greater than to 0",
      "Must be greater than to 0",
      value => Number(value) > 0
    )
    .test(
      "less than or equal to 220",
      "Must be less than or equal to 220",
      value => Number(value) <= 220
    )
});
