export class Franchise {
  static deserializer(franchise) {
    return {
      id: franchise.id,
      name: franchise.name,
      createdAt: franchise.created_at,
      updatedAt: franchise.updated_at,
      status: franchise.status
    };
  }
}
