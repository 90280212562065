import { useQuery } from "@tanstack/react-query";
import { DailyInputsController } from "../../../networking/controllers/daily-inputs-controller";

export const useDailyInputs = params => {
  const query = useQuery({
    queryKey: ["dailyInputs", params],
    queryFn: () => DailyInputsController.fetchDailyInputs(params),
    keepPreviousData: true
  });

  return query;
};
