import { api } from "../../config/httpRequest";
import { Franchise } from "../serializers/franchise.serializer";

export class FranchisesController {
  static async fetchFranchises() {
    const response = await api({
      method: "GET",
      endpoint: "/franchises"
    });

    const franchises = await response.json;

    return franchises.map(franchise => Franchise.deserializer(franchise));
  }

  static async create({ data }) {
    const response = await api({
      method: "POST",
      endpoint: `/franchises`,
      payload: data
    });

    return await response.json;
  }

  static async update({ where, data }) {
    const response = await api({
      method: "PATCH",
      endpoint: `/franchises/${where.id}`,
      payload: { franchise: data }
    });

    return await response.json;
  }
}
