import React from "react";
import { calculateTriageStatusColor } from "../../utils/calculateTriageStatusColor";

export function TriageStatusRow({ row }) {
  const statusColor = calculateTriageStatusColor(row.triageStatus);

  return (
    <td
      className="relative mr-2 flex justify-center items-center"
      style={{ height: "inherit" }}
    >
      <div
        style={{
          width: "61px",
          height: "16px",
          borderRadius: "100px"
        }}
        className={statusColor}
      />
      {row.triageStatus && !row.triageStatus.isAuto && (
        <div className="absolute -right-1.5">
          <img src="/logo-inspire.svg" />
        </div>
      )}
    </td>
  );
}
