import { useQuery } from "@tanstack/react-query";
import { FranchisesController } from "../../networking/controllers/franchises-controller";

export const useFranchises = () => {
  const { data: franchises } = useQuery({
    queryKey: ["franchises"],
    queryFn: () => FranchisesController.fetchFranchises()
  });

  return franchises;
};
