import React from "react";
import { Field } from "../../common/Field";
import { Select } from "../../common/Select";
import { useCoaches } from "../../coaches/hooks/queries/useCoach";

export default function ProgramFields({ client, editable }) {
  const coaches = useCoaches();

  return (
    <div className="w-full p-10 pb-4 bg-white rounded-lg shadow-container relative grid grid-cols-5 gap-x-4">
      <Field
        containerStyles="col-span-2"
        label="Program"
        name="programName"
        disabled
      />
      <Field
        type="date"
        label="Start Date"
        name="startDate"
        className="px-2"
        disabled={!editable}
      />
      <Field
        className="text-center"
        label="Program Length (Weeks)"
        name="programLength"
        disabled={!editable}
      />
      <Field
        className="text-center"
        label="Body Sculpting (Sessions)"
        name="bodySculpting"
        disabled
      />
      <Select
        name="coachId"
        label="Assigned Health Coach"
        disabled={!editable}
        hideRollbackButton
      >
        {coaches.map(coach => (
          <option value={coach.id} key={coach.id}>
            {coach.name}
          </option>
        ))}
      </Select>
      <Field label="Phase" name="programPhase" disabled />
      <Field
        className="text-center"
        label="Average Daily Weight Loss"
        name="averageWeightLoss"
        disabled
      />
      <Field
        className="text-center"
        label="Days in Round"
        name="daysInRound"
        disabled
      />
      <Field label="Status" name="programStatus" disabled />
      <a
        className="absolute -top-8 -right-8"
        href={`https://wa.me/${client.phone.replace("-", "").replace("+", "")}`}
        target="_blank"
      >
        <img src="/logo-whatsapp.svg" width="90" />
      </a>
    </div>
  );
}
