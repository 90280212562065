import { api } from "../../config/httpRequest";
import { DailyInputSerializer } from "../serializers/daily-input-serializer";

export class DailyInputsController {
  static async fetchDailyInputs(query) {
    const response = await api({
      method: "GET",
      endpoint: "/daily_inputs/get_history",
      query
    });

    const data = await response.json;

    return {
      currentPage: data.current_page,
      totalPages: data.total_pages,
      dailyInputs: data.daily_inputs.map(dailyInput =>
        DailyInputSerializer.deserialize(dailyInput)
      )
    };
  }

  static async updateDailyInput({ where, data }) {
    const response = await api({
      method: "PATCH",
      endpoint: `/clients/${where.clientId}/programs/${where.programId}/daily_inputs/${where.id}`,
      payload: { daily_input: data }
    });

    return await response.json;
  }
}
