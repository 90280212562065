import React, { useState } from "react";
import { ClientsController } from "../../networking/controllers/clients-controller";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Switch } from "../../common/Switch";
import { Modal } from "../../common/Modal";
import { Button } from "../../common/Button";
import { toast } from "react-hot-toast";

const SwitchClientStatus = ({ client }) => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState(null);

  const switchSelected = {
    active: "left",
    inactive: "right"
  }[client.status];

  const updateClientMutation = useMutation(
    ({ where, data }) => ClientsController.updateClient({ where, data }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries();
      }
    }
  );

  const updateClientStatus = async status => {
    const text = {
      active: "activated",
      inactive: "deactivated"
    }[status];

    const clientData = {
      where: { id: client.id },
      data: { status }
    };

    await toast.promise(updateClientMutation.mutateAsync(clientData), {
      loading: "Updating client",
      success: `Client ${text} successfully`,
      error: "Couldn't update client status"
    });
  };

  const onChange = async selected => {
    const status = {
      left: "active",
      right: "inactive"
    }[selected];

    const hasOngoingProgram =
      client.status.toLowerCase() === "active" &&
      client.lastProgram.status.toLowerCase() !== "finished";

    if (hasOngoingProgram) {
      setShowModal(true);
      setStatus(status);
    } else {
      try {
        await updateClientStatus(status);
      } catch (error) {
      } finally {
        await queryClient.refetchQueries();
      }
    }
  };

  const onConfirm = async () => {
    try {
      await updateClientStatus(status);
    } catch (error) {
    } finally {
      await queryClient.refetchQueries();
      setStatus(null);
      setShowModal(false);
    }
  };

  const onCancel = () => {
    setStatus(null);
    setShowModal(false);
  };

  return (
    <>
      <Switch
        initialValue={switchSelected}
        leftText="Active"
        rightText="Inactive"
        getSelectedCallback={onChange}
      />
      <Modal isHidden={!showModal}>
        <div className="flex flex-col items-center bg-white p-8 rounded-lg gap-8">
          <p>
            Are you sure you want to deactivate? This will end current program
          </p>
          <div className="flex w-full gap-8">
            <Button style="darken" text="Discard" onClick={onCancel} />
            <Button style="darken" text="Confirm" onClick={onConfirm} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SwitchClientStatus;
