import React, { useEffect, useState, useRef } from "react";
import { TriageStatusRow } from "./TriageStatusRow";
import { Note } from "../../common/Note";

export function NoteRow({ program, row, onSubmitRow }) {
  const handleOnSubmit = async note => {
    await onSubmitRow({
      row,
      data: { note },
      message: {
        loading: "Updating note",
        success: "Note was successfully updated",
        error: "Couldn't update note"
      }
    });
  };

  return (
    <>
      <td className="border-r-0.5 px-4 py-4">{row.date}</td>
      <td className="border-r-0.5">
        <Note
          resize
          initialValue={row.note ?? ""}
          maxCharacters={300}
          placeholder={
            program.status === "ongoing" ? "Enter your note here" : undefined
          }
          disabled={program.status === "finished"}
          onSubmit={handleOnSubmit}
        />
      </td>
      <td className="border-r-0.5 px-4 py-4">
        {row.daysInRound.numberOfDays} of {row.daysInRound.totalDays}
      </td>
      <TriageStatusRow row={row} />
    </>
  );
}
