import React, { useState } from "react";
import { Form } from "../../common/Form";
import { Field } from "../../common/Field";
import { Modal } from "../../common/Modal";
import { Button } from "../../common/Button";
import { programInformationSchema } from "../../validations/clients";
import { ProgramsController } from "../../networking/controllers/programs-controller";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

export const NewProgramModal = ({ client }) => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);

  const initialValues = {
    phase: 1,
    programName: "Reveal Lifestyle Program"
  };

  const mutation = useMutation({
    mutationFn: ({ where, data }) =>
      ProgramsController.createProgram({ where, data }),
    onSuccess: async () => {
      await queryClient.refetchQueries();
    }
  });

  const onSubmit = async data => {
    const program = {
      start_weight_pounds: data.startWeightPounds,
      start_weight_ounces: data.startWeightOunces,
      goal_weight_pounds: data.goalWeightPounds,
      goal_weight_ounces: data.goalWeightOunces,
      length: data.programLength,
      body_sculpting: data.bodySculpting,
      start_date: data.startDate,
      phase: data.phase
    };

    try {
      await toast.promise(
        mutation.mutateAsync({
          where: {
            clientId: client.id
          },
          data: program
        }),
        {
          loading: "Creating program",
          success: "Program was successfully created",
          error: "Couldn't create program"
        }
      );
    } catch (error) {
    } finally {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  };

  return (
    <>
      <button
        className="transition duration-200 ease-in-out px-6 h-8 rounded bg-neutral-500 text-white cursor-pointer hover:bg-neutral-300"
        onClick={() => {
          setShowModal(true);
          document.querySelector("body").classList.add("overflow-hidden");
        }}
      >
        <span>NEW PROGRAM</span>
      </button>
      <Modal isHidden={!showModal}>
        <div className="w-1/3 bg-white p-8 rounded-lg">
          <Form
            initialValues={initialValues}
            schema={programInformationSchema.omit(["coachId"])}
            onSubmit={onSubmit}
          >
            <div className="grid grid-cols-2 gap-x-8 pb-8">
              <div className="col-span-2">
                <Field
                  label="Program name"
                  name="programName"
                  disabled={true}
                />
              </div>
              <Field
                className="text-center"
                type="number"
                label="Program length (weeks)"
                name="programLength"
                placeholder="weeks"
              />
              <Field
                className="text-center"
                type="number"
                label="Body sculpting (sessions)"
                name="bodySculpting"
                placeholder="sessions"
              />
              <Field
                className="text-center"
                type="number"
                label="Start weight pounds"
                name="startWeightPounds"
                placeholder="lbs"
              />
              <Field
                className="text-center"
                type="number"
                label="Start weight ounces"
                name="startWeightOunces"
                placeholder="oz"
              />
              <Field
                className="text-center"
                type="number"
                label="Goal weight pounds"
                name="goalWeightPounds"
                placeholder="lbs"
              />
              <Field
                className="text-center"
                type="number"
                label="Goal weight ounces"
                name="goalWeightOunces"
                placeholder="oz"
              />
              <Field
                className="text-center"
                type="date"
                name="startDate"
                label="Start program date"
              />
              <Field
                className="text-center"
                type="number"
                name="phase"
                label="Program phase"
              />
            </div>
            <div className="flex w-full gap-8">
              <Button
                type="button"
                style="darken"
                text="Discard"
                onClick={() => {
                  setShowModal(false);
                  document
                    .querySelector("body")
                    .classList.remove("overflow-hidden");
                }}
              />
              <Button type="submit" style="darken" text="Confirm" />
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
