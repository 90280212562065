import { useQuery } from "@tanstack/react-query";
import { CoachesController } from "../../../networking/controllers/coaches-controller";

export const useCoaches = () => {
  const { data: coaches } = useQuery({
    queryKey: ["coaches"],
    queryFn: CoachesController.fetchCoaches
  });

  return coaches;
};
