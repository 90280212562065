import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FullScreenLoader } from "./common/FullScreenLoader";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";

export const App = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: true
      }
    }
  });

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Toaster
        position="bottom-center"
        toastOptions={{
          className: "mb-6",
          success: {
            style: {
              background: "#1ACC99",
              color: "white"
            },
            iconTheme: {
              primary: "white",
              secondary: "#1ACC99"
            }
          },
          error: {
            style: {
              background: "#F64949",
              color: "white"
            },
            iconTheme: {
              primary: "white",
              secondary: "#F64949"
            }
          }
        }}
      />
      <QueryClientProvider client={queryClient}>
        {children}
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </Suspense>
  );
};
