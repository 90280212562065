import React from "react";
import { Form } from "../common/Form";
import { Field } from "../common/Field";
import { Select } from "../common/Select";
import { foodJournalCategories } from "../utils/constants";
import { mealSchema } from "../validations/daily-inputs";

// import assets to avoid bugs when showing up between renders
import closeIcon from "../../../../public/x-black.svg";
import doneIcon from "../../../../public/done.svg";

export const EntryForm = ({
  meal,
  program,
  dailyInput,
  initialValues,
  onSubmit,
  destroyMeal
}) => {
  const handleSubmit = async data => {
    await onSubmit({
      where: {
        id: initialValues.id,
        dailyInputId: dailyInput.id,
        programId: program.id,
        clientId: program.clientId
      },
      data: {
        ...data,
        meal
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      schema={mealSchema}
    >
      {formMethods => (
        <div className="relative flex gap-4 w-11/12">
          <Field
            name="specify"
            placeholder="Specify"
            label="Meal description"
          />
          <Field name="ounces" placeholder="oz" label="Ounces" />
          <Select name="category" label="Choose category">
            {foodJournalCategories.map((category, index) => (
              <option value={category} key={index}>
                {category}
              </option>
            ))}
          </Select>
          <div className="absolute flex gap-4 flex-none -right-16 top-8">
            <button type="button">
              <img
                src={closeIcon}
                className="cursor-pointer"
                onClick={async () => {
                  await destroyMeal({
                    where: {
                      id: initialValues.id,
                      dailyInputId: dailyInput.id,
                      programId: program.id,
                      clientId: program.clientId
                    }
                  });
                }}
              />
            </button>
            <button type="submit">
              <img src={doneIcon} className="cursor-pointer" />
            </button>
          </div>
        </div>
      )}
    </Form>
  );
};
