import * as yup from "yup";
import { ClientsController } from "../networking/controllers/clients-controller";
import {
  PHONE_REGEX,
  FULL_NAME_REGEX,
  EMAIL_REGEX,
  ONLY_NUMBERS_REGEX,
  SELECT_PLACEHOLDERS
} from "../utils/constants";

const onlyNumbersYupValidator = yup
  .string()
  .matches(ONLY_NUMBERS_REGEX, "You must enter a number")
  .required("Can't be empty");

const clientInformationSchema = yup.object({
  clientName: yup
    .string()
    .required("Can't be empty")
    .matches(FULL_NAME_REGEX, "First and Last Name Required"),
  clientPhone: yup
    .string()
    .required("Can't be empty")
    .matches(PHONE_REGEX, "Invalid Phone number"),
  clientEmail: yup
    .string()
    .required("Can't be empty")
    .matches(EMAIL_REGEX, "Invalid Email")
    .when("$dirtyFields.clientEmail", {
      is: true,
      then: schema =>
        schema.test("uniqueEmail", "Email already in use", async value => {
          const client = await ClientsController.searchClient({
            email: value,
            throw_not_found: false
          });

          return !client;
        })
    })
});

export const programInformationSchema = yup.object({
  startWeightPounds: onlyNumbersYupValidator,
  startWeightOunces: onlyNumbersYupValidator
    .test(
      "startWeightOunces",
      "Must be greater than or equal to 0",
      value => Number(value) >= 0
    )
    .test(
      "startWeightOunces",
      "Must be less than or equal to 16",
      value => Number(value) <= 16
    ),
  goalWeightPounds: onlyNumbersYupValidator,
  goalWeightOunces: onlyNumbersYupValidator
    .test(
      "goalWeightOunces",
      "Must be greater than or equal to 0",
      value => Number(value) >= 0
    )
    .test(
      "goalWeightOunces",
      "Must be less than or equal to 16",
      value => Number(value) <= 16
    ),
  programName: yup.string().required("Can't be empty"),
  programLength: onlyNumbersYupValidator
    .test(
      "programLength",
      "Must be greater than or equal to 3",
      value => Number(value) >= 3
    )
    .test(
      "programLength",
      "Must be less than or equal to 24",
      value => Number(value) <= 24
    ),
  bodySculpting: onlyNumbersYupValidator.test(
    "bodySculpting",
    "Must be less than or equal to 72",
    value => Number(value) <= 72
  ),
  startDate: yup
    .date()
    .typeError("You must enter a date")
    .required("Can't be empty"),
  coachId: yup
    .string()
    .required("Can't be empty")
    .notOneOf([SELECT_PLACEHOLDERS.COACH], "You must select a coach"),
  phase: onlyNumbersYupValidator
    .test(
      "phase",
      "Must be greater than or equal to 1",
      value => Number(value) >= 1
    )
    .test(
      "phase",
      "Must be less than or equal to 4",
      value => Number(value) <= 4
    )
});

const clientInformationCredentials = yup.object({
  userEmailCredential: yup.string().required("Can't be empty"),
  userPasswordCredential: yup.string().required("Can't be empty")
});

export const newClientSchemasByStep = {
  1: clientInformationSchema,
  2: programInformationSchema,
  3: clientInformationCredentials
};

export const searchClientSchema = yup.object({
  name: yup.string().nullable(),
  phone: yup.string().nullable(),
  email: yup.string().nullable()
});

export const editClientForm = yup
  .object({
    clientCode: yup.string().required("Can't be empty"),
    programLength: onlyNumbersYupValidator.when(
      "$lastValues.programLength",
      ([lastValue], schema) =>
        schema.test(
          "programLength",
          `Must be greater than or equal to ${lastValue}`,
          value => Number(value) >= Number(lastValue)
        )
    )
  })
  .concat(clientInformationSchema)
  .concat(programInformationSchema.omit(["programLength", "bodySculpting"]));

export const dailyEntriesSchema = yup.object({
  weightPounds: onlyNumbersYupValidator,
  weightOunces: onlyNumbersYupValidator
    .test(
      "weightOunces",
      "Must be greater than or equal to 0",
      value => Number(value) >= 0
    )
    .test(
      "weightOunces",
      "Must be less than or equal to 16",
      value => Number(value) <= 16
    ),
  waterOunces: onlyNumbersYupValidator.test(
    "waterOunces",
    "Must be greater than or equal to 0",
    value => Number(value) >= 0
  ),
  bowelMovement: onlyNumbersYupValidator,
  sleepHours: onlyNumbersYupValidator
    .test(
      "sleepHours",
      "Must be greater than or equal to 0",
      value => Number(value) >= 0
    )
    .test(
      "sleepHours",
      "Must be less than or equal to 23",
      value => Number(value) <= 23
    ),
  sleepMinutes: onlyNumbersYupValidator
    .test(
      "sleepMinutes",
      "Must be greater than or equal to 0",
      value => Number(value) >= 0
    )
    .test(
      "sleepMinutes",
      "Must be less than or equal to 59",
      value => Number(value) <= 59
    )
});
