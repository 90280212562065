import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Switch } from "../../common/Switch";
import { useClientPrograms } from "../hooks/queries/useClientPrograms";
import { parseFormProgramData } from "../../utils/parseFormProgramData";

const ProgramSwitch = ({ client, setProgram }) => {
  const [showProgram, setShowProgram] = useState("last");
  const programs = useClientPrograms({
    where: {
      clientId: client.id
    },
    query: {
      exclude_last_program: true,
      order: "DESC",
      status: "finished"
    }
  });

  const switchSelected = {
    last: "left",
    previous: "right"
  }[showProgram];

  const handleOnChangeSelectPrograms = async e => {
    const programId = e.target.value;
    const program = programs.find(program => program.id === programId);

    setProgram(parseFormProgramData(client, program));
  };

  //   default order DESC
  const lastPreviousProgram = programs[0];
  //   const lastPreviousProgram = programs[programs.length - 1]; if the order is seted as ASC

  useEffect(() => {
    if (showProgram === "previous") {
      setProgram(parseFormProgramData(client, lastPreviousProgram));
    } else {
      setProgram(null);
    }
  }, [showProgram]);

  const onChangeShowProgram = selected => {
    const show = {
      left: "last",
      right: "previous"
    }[selected];

    setShowProgram(show);
  };

  return (
    programs.length > 0 && (
      <>
        <div className="w-full px-10 bg-white flex justify-between items-center">
          <Switch
            className="flex pt-6"
            initialValue={switchSelected}
            leftText={
              client.lastProgram.status === "finished"
                ? "Last Program"
                : "Active Program"
            }
            rightText="Previous programs"
            getSelectedCallback={onChangeShowProgram}
            selectedStyle="border-primary-300"
            unSelectedStyle="border-neutral-50"
            optionsStyle="pb-2.5 px-4 rounded-none border-b-2 "
          />
          {showProgram === "previous" && (
            <form className="w-min">
              <select
                className="p-1 px-4 bg-white border border-neutral-100 rounded"
                defaultValue={lastPreviousProgram.id}
                onChange={handleOnChangeSelectPrograms}
              >
                {programs.map(program => {
                  const startDate = format(
                    new Date(`${program.startDate}T00:00:00`),
                    "MM/dd/yy"
                  );

                  const endDate = program.endDate
                    ? format(
                        new Date(`${program.endDate}T00:00:00`),
                        "MM/dd/yy"
                      )
                    : "-";

                  return (
                    <option value={program.id} key={program.id}>
                      {program.name} - {startDate} to {endDate}
                    </option>
                  );
                })}
              </select>
            </form>
          )}
        </div>
      </>
    )
  );
};

export default ProgramSwitch;
