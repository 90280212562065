import React from "react";

export default function DailyInputTabs({ tabs, selectedTab, onChangeTab }) {
  return (
    <div className="flex">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`px-4 py-2 cursor-pointer ${
            tab === selectedTab
              ? "border-primary-300 border-b-2"
              : "border-neutral-50 border-b-2"
          }`}
          onClick={() => onChangeTab(tab)}
        >
          <span className="font-monts text-black font-semibold text-xs">
            {tab}
          </span>
        </div>
      ))}
    </div>
  );
}
