// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "@rails/request.js";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Tailwind CSS
import "stylesheets/application";
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

//Function to disable ENTER KEY Pressed default actions.
document.addEventListener("turbolinks:load", () => {
  const form = document.getElementById("form-id");
  const submitButton = document.getElementById("form-submit-button-id");
  if (form) {
    form.addEventListener("keydown", e => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (submitButton) submitButton.click();
      }
    });
  }
});
