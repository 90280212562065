import React, { useState } from "react";
import { TriageStatusRow } from "./TriageStatusRow";
import { JournalModal } from "../../food-journals/JournalsModal";

export const FoodJournalRow = ({ program, dailyInput, dailyInputQuery }) => {
  const [journalToShow, setJournalToShow] = useState(null);

  const hideModal = () => {
    setJournalToShow(null);
  };

  return (
    <>
      <td className="border-r-0.5 px-4 py-4 align-top">{dailyInput.date}</td>
      <td className="border-r-0.5 px-4 py-4 align-top relative">
        <p>{dailyInput.foodJournal.lunch.formatted}</p>
        {program.status === "ongoing" && (
          <button
            className="m-2 absolute top-0 right-0 flex items-center gap-2"
            type="button"
            onClick={() => setJournalToShow("Lunch")}
          >
            <img src="/edit.svg" width={15} />
          </button>
        )}
      </td>
      <td className="border-r-0.5 px-4 py-4 align-top relative">
        <p>{dailyInput.foodJournal.dinner.formatted}</p>
        {program.status === "ongoing" && (
          <button
            className="m-2 absolute top-0 right-0 flex items-center gap-2"
            type="button"
            onClick={() => setJournalToShow("Dinner")}
          >
            <img src="/edit.svg" width={15} />
          </button>
        )}
      </td>
      <td className="border-r-0.5 px-4 py-4 align-top">
        {dailyInput.daysInRound.numberOfDays} of{" "}
        {dailyInput.daysInRound.totalDays}
      </td>
      <TriageStatusRow row={dailyInput} />

      {journalToShow && (
        <td>
          <JournalModal
            type={journalToShow}
            dailyInput={dailyInput}
            program={program}
            hideModal={hideModal}
            dailyInputQuery={dailyInputQuery}
          />
        </td>
      )}
    </>
  );
};
