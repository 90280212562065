import React, { useRef, useEffect } from "react";

export const Hamburger = ({ children, onClose }) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) onClose();
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return (
    <ul
      ref={ref}
      className="z-50 w-56 origin-top-right absolute right-0 m-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      onClick={onClose}
    >
      {children}
    </ul>
  );
};
