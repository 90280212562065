import Toast from "../common/Toast";
import React, { createContext, useContext, useState } from "react";

const initialState = {
  message: "",
  status: "error",
  isActive: false
};

const ToastContext = createContext(initialState);

export const ToastProvider = ({ children }) => {
  const [toastData, setToastData] = useState(initialState);

  return (
    <ToastContext.Provider value={{ toastData, setToastData }}>
      {children}
      <Toast
        toastData={toastData}
        dismiss={() =>
          setToastData({
            message: "",
            status: "error",
            isActive: false
          })
        }
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
