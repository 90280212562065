import React from "react";
import { Field } from "../../../common/Field";
import { Select } from "../../../common/Select";
import { useCoaches } from "../../../coaches/hooks/queries/useCoach";
import { SELECT_PLACEHOLDERS } from "../../../utils/constants";
import { PhoneFiled } from "../../../common/PhoneField";

export const FieldsByStep = ({ step }) => {
  switch (step) {
    case 1:
      return (
        <>
          <span className="text-xs font-bold">Contact information</span>
          <Field containerStyles="mt-6" name="clientName" label="Full Name" />
          <PhoneFiled containerStyles="mt-4" name="clientPhone" label="Phone" />
          <Field containerStyles="mt-4" name="clientEmail" label="Email" />
        </>
      );
    case 2:
      const coaches = useCoaches();

      return (
        <>
          <div>
            <span className="text-xs font-bold">Start Weight</span>
            <div className="flex justify-between gap-4">
              <Field
                containerStyles="mt-2 mb-4"
                className="text-center"
                type="number"
                name="startWeightPounds"
                label="Pounds"
                placeholder="lbs"
              />
              <Field
                containerStyles="mt-2 mb-4"
                className="text-center"
                type="number"
                name="startWeightOunces"
                label="Ounces"
                placeholder="oz"
              />
            </div>
          </div>
          <div>
            <div>
              <span className="text-xs font-bold">Goal Weight</span>
              <div className="flex justify-between gap-4">
                <Field
                  containerStyles="mt-2 mb-4"
                  className="text-center"
                  type="number"
                  name="goalWeightPounds"
                  label="Pounds"
                  placeholder="lbs"
                />
                <Field
                  containerStyles="mt-2 mb-4"
                  className="text-center"
                  type="number"
                  name="goalWeightOunces"
                  label="Ounces"
                  placeholder="oz"
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <span className="text-xs font-bold">Program Information</span>
              <Field
                containerStyles="mt-2 mb-4"
                name="programName"
                label="Name"
                disabled
              />
              <div className="flex justify-between gap-4">
                <Field
                  containerStyles="mt-2 mb-4"
                  className="text-center"
                  type="number"
                  name="programLength"
                  label="Program length (weeks)"
                  placeholder="weeks"
                />
                <Field
                  containerStyles="mt-2 mb-4"
                  className="text-center"
                  type="number"
                  name="bodySculpting"
                  label="Body sculpting (sessions)"
                  placeholder="sessions"
                />
              </div>
              <Field
                containerStyles="mt-2 mb-4"
                className="text-center"
                type="date"
                name="startDate"
                label="Start program date"
              />
              <div className="flex flex-row items-baseline gap-4">
                <Field
                  containerStyles="mt-2 mb-4"
                  className="text-center"
                  type="number"
                  name="phase"
                  label="Program phase"
                />
                <Select
                  name="coachId"
                  label="Assigned Health Coach"
                  hideRollbackButton
                >
                  <option value={SELECT_PLACEHOLDERS.COACH} disabled hidden>
                    {SELECT_PLACEHOLDERS.COACH}
                  </option>
                  {coaches.map(coach => (
                    <option value={coach.id} key={coach.id}>
                      {coach.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <div>
          <span className="text-xs font-bold">Client Login Credentials</span>
          <Field
            containerStyles="mt-4"
            name="userEmailCredential"
            label="User"
            disabled
          />
          <Field
            containerStyles="mt-4"
            name="userPasswordCredential"
            label="Password"
            disabled
          />
        </div>
      );
  }
};
