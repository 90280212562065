import React from "react";

export function Hamburger(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      viewBox="0 -1 20 20"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-210 -887)">
          <path d="M229 895h-18a1 1 0 000 2h18a1 1 0 100-2zm0 8h-18a1 1 0 000 2h18a1 1 0 100-2zm-18-14h18a1 1 0 100-2h-18a1 1 0 000 2z"></path>
        </g>
      </g>
    </svg>
  );
}
