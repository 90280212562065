const calculateTriageStatusColor = (triageStatus) => {
  const triageStatusColor = {
    green: "bg-complementary-success",
    yellow: "bg-complementary-warning",
    red: "bg-complementary-error",
  };

  if (triageStatus?.color) return triageStatusColor[triageStatus?.color];
};

export { calculateTriageStatusColor };
