import React from "react";
import { NoteRow } from "./NoteRow";
import { WeightRow } from "./WeightRow";
import { WaterIntakeRow } from "./WaterIntakeRow";
import { BowelMovementRow } from "./BowelMovementRow";
import { HoursOfSleepRow } from "./HoursOfSleepRow";
import { FoodJournalRow } from "./FoodJournalRow";

export default function DailyInputTable({
  program,
  setModalContent,
  selectedTab,
  onSubmitRow,
  dailyInputQuery,
  setShowModal
}) {
  const headersMapping = {
    Weight: [
      "Date",
      "Daily Weight",
      "Weight Loss to Date",
      "Average Daily Weight Loss",
      "Days in Round",
      "Status"
    ],
    "Water Intake": ["Date", "Daily Input", "Days in Round", "Status"],
    "Food Journal": ["Date", "Lunch", "Dinner", "Days in Round", "Status"],
    "Bowel Movement": ["Date", "Times a Day", "Days in Round", "Status"],
    "Hours of Sleep": ["Date", "Time per Day", "Days in Round", "Status"],
    Notes: ["Date", "Notes", "Days in Round", "Status"]
  };
  return (
    <div className="w-full mt-6">
      <table className="w-full max-h-full text-xs text-left">
        <thead className="h-14 sticky top-0 bg-secondary-500 text-white border-white">
          <tr>
            {headersMapping[selectedTab].map((header, index) => {
              return (
                <th
                  key={index}
                  className={`h-14 font-medium px-4 border-r-0.5 ${
                    header === "Notes" ||
                    header === "Lunch" ||
                    header === "Dinner"
                      ? ""
                      : "w-1/6"
                  } 
                  `}
                >
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <BodyRows
            setShowModal={setShowModal}
            setModalContent={setModalContent}
            program={program}
            selectedTab={selectedTab}
            dailyInputQuery={dailyInputQuery}
            onSubmitRow={onSubmitRow}
          />
        </tbody>
      </table>
    </div>
  );
}

function BodyRows({
  setShowModal,
  setModalContent,
  program,
  selectedTab,
  onSubmitRow,
  dailyInputQuery
}) {
  return (
    <>
      {dailyInputQuery.data.dailyInputs.length > 0 && (
        <>
          {dailyInputQuery.data.dailyInputs.map((row, index) => {
            switch (selectedTab) {
              case "Weight":
                return (
                  <tr key={index} className="border-0.5 h-14 text-neutral-300">
                    <WeightRow
                      setShowModal={setShowModal}
                      setModalContent={setModalContent}
                      program={program}
                      row={row}
                      onSubmitRow={onSubmitRow}
                    />
                  </tr>
                );
              case "Water Intake":
                return (
                  <tr key={index} className="border-0.5 h-14 text-neutral-300">
                    <WaterIntakeRow
                      setShowModal={setShowModal}
                      setModalContent={setModalContent}
                      program={program}
                      row={row}
                      onSubmitRow={onSubmitRow}
                    />
                  </tr>
                );
              case "Food Journal":
                return (
                  <tr key={index} className="border-0.5 h-14 text-neutral-300">
                    <FoodJournalRow
                      dailyInput={row}
                      setShowModal={setShowModal}
                      setModalContent={setModalContent}
                      program={program}
                      dailyInputQuery={dailyInputQuery}
                    />
                  </tr>
                );
              case "Bowel Movement":
                return (
                  <tr key={index} className="border-0.5 h-14 text-neutral-300">
                    <BowelMovementRow
                      setShowModal={setShowModal}
                      setModalContent={setModalContent}
                      program={program}
                      row={row}
                      onSubmitRow={onSubmitRow}
                    />
                  </tr>
                );
              case "Hours of Sleep":
                return (
                  <tr key={index} className="border-0.5 h-14 text-neutral-300">
                    <HoursOfSleepRow
                      setShowModal={setShowModal}
                      setModalContent={setModalContent}
                      program={program}
                      row={row}
                      onSubmitRow={onSubmitRow}
                    />
                  </tr>
                );
              case "Notes":
                return (
                  <tr key={index} className="border-0.5 h-14 text-neutral-300">
                    <NoteRow
                      program={program}
                      row={row}
                      onSubmitRow={onSubmitRow}
                    />
                  </tr>
                );
            }
          })}
        </>
      )}
    </>
  );
}
