import React, { useState } from "react";
import { useFranchises } from "../hooks/useFranchises";
import { FranchiseItem } from "./FranchiseItem";
import { Button } from "../../common/Button";
import { Form } from "../../common/Form";
import { franchiseInformationSchema } from "../../validations/franchises";
import { Field } from "../../common/Field";
import { toast } from "react-hot-toast";
import { FranchisesController } from "../../networking/controllers/franchises-controller";

import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { Modal } from "../../common/Modal";

export const FranchisesModal = ({ onClose }) => {
  const franchises = useFranchises();
  const [showFranchiseForm, setShowFranchiseForm] = useState(false);

  return (
    <Modal>
      <div className="w-3/5 bg-white p-8 rounded-lg">
        {showFranchiseForm ? (
          <FranchiseForm
            onClose={() => {
              setShowFranchiseForm(false);
            }}
          />
        ) : (
          <>
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-medium">Franchises</p>
              <button
                className="w-10 h-10 bg-neutral-500 rounded-full"
                onClick={() => {
                  setShowFranchiseForm(true);
                }}
              >
                <img src="/plus.svg" className="m-auto" />
              </button>
            </div>
            <div className="overflow-auto" style={{ maxHeight: "50vh" }}>
              {franchises.map(franchise => (
                <FranchiseItem franchise={franchise} key={franchise.id} />
              ))}
            </div>
            <Button
              otherClases="mt-8"
              type="button"
              style="darken"
              text="Close"
              onClick={onClose}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const FranchiseForm = ({ onClose }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: data => FranchisesController.create({ data }),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["franchises"] });
      onClose();
    }
  });

  const onSubmit = async data => {
    const franchise = {
      name: data.franchiseName
    };

    try {
      await toast.promise(mutation.mutateAsync({ franchise }), {
        loading: "Creating franchise",
        success: "Franchise was successfully created",
        error: "Couldn't create franchise"
      });
    } catch (error) {}
  };

  return (
    <Form
      schema={franchiseInformationSchema}
      onSubmit={onSubmit}
      onSubmitButton={({ formMethods }) => {
        return <Button type="submit" style="darken" text="Confirm" />;
      }}
      onCancelButton={({ formMethods }) => {
        return (
          <Button
            type="button"
            style="darken"
            text="Discard"
            onClick={onClose}
          />
        );
      }}
      buttonsContainer={({ children }) => (
        <div className="w-full flex items-center mt-4 gap-4">{children}</div>
      )}
    >
      <p className="mb-4">NEW FRANCHISE</p>
      <Field name="franchiseName" label="Name" />
    </Form>
  );
};
