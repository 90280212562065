import React, { useEffect, useState } from "react";
import { Button } from "../common/Button";
import { EntryForm } from "../food-journals/EntryForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FoodJournalsController } from "../networking/controllers/food-journals-controller";
import { toast } from "react-hot-toast";
import { useFoodJournalMutation } from "./hooks/mutations/useFoodJournal";

const defaultEntry = {
  id: "new-1",
  category: "Category",
  specify: null,
  ounces: null
};

export const JournalModal = ({
  type,
  dailyInput,
  program,
  hideModal,
  dailyInputQuery
}) => {
  const foodJournalMutation = useFoodJournalMutation();

  const initialEntries = dailyInput.foodJournal[type.toLowerCase()].entries;
  const [entries, setEntries] = useState(
    initialEntries.length > 0 ? initialEntries : [defaultEntry]
  );

  useEffect(() => {
    document.querySelector("body").classList.add("overflow-hidden");

    return () => {
      document.querySelector("body").classList.remove("overflow-hidden");
    };
  }, []);

  const addEntry = (entry = defaultEntry) => {
    setEntries(prev => [
      {
        ...entry,
        id: `new-${entries.length + 1}`
      },
      ...prev
    ]);
  };

  const onCreate = async ({ where, data }) => {
    try {
      const { response } = await toast.promise(
        foodJournalMutation.create.mutateAsync({ where, data }),
        {
          loading: "Creating food journal",
          success: "Food journal was successfully created",
          error: "Couldn't create food journal"
        }
      );
      const newEntry = await response.json();

      const newEntries = entries.map(entry => {
        if (entry.id === where.id) return newEntry;
        return entry;
      });

      setEntries(newEntries);
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdate = async ({ where, data }) => {
    try {
      await toast.promise(
        foodJournalMutation.update.mutateAsync({ where, data }),
        {
          loading: "Updating food journal",
          success: "Food journal was successfully updated",
          error: "Couldn't update food journal"
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async ({ data, where }) => {
    try {
      if (isNaN(where.id) && where.id.includes("new")) {
        await onCreate({ where, data });
      } else {
        await onUpdate({ where, data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const destroyMeal = async ({ where }) => {
    try {
      if (!(isNaN(where.id) && where.id.includes("new"))) {
        await toast.promise(foodJournalMutation.delete.mutateAsync({ where }), {
          loading: "Deleting food journal",
          success: "Food journal was successfully deleted",
          error: "Couldn't delete food journal"
        });
      }

      setEntries(entries => {
        const newEntries = entries.filter(entry => entry.id !== where.id);

        if (!newEntries.length > 0) return [defaultEntry];

        return newEntries;
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="z-50 w-full h-screen fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center text-base"
      style={{ background: "rgb(0 0 0 / 50%)" }}
    >
      <div className="w-3/5 bg-white p-8 rounded-lg">
        <div className="w-full flex justify-between items-center">
          <p className="mb-4 text-black">{type}</p>
          <button
            className="w-10 h-10 bg-neutral-500 rounded-full"
            onClick={() => {
              addEntry();
            }}
          >
            <img src="/plus.svg" className="m-auto" />
          </button>
        </div>
        <div
          className="w-full overflow-auto pt-5"
          style={{ maxHeight: "75vh" }}
        >
          {entries.map((entry, index) => {
            const id = entry.id ?? `"new-"${index}`;

            return (
              <React.Fragment key={id}>
                <EntryForm
                  initialValues={{
                    ...entry,
                    id
                  }}
                  program={program}
                  dailyInput={dailyInput}
                  meal={type}
                  onSubmit={onSubmit}
                  destroyMeal={destroyMeal}
                />
              </React.Fragment>
            );
          })}
        </div>
        <div className="pt-4 flex justify-end">
          <Button
            otherClases="w-min"
            type="button"
            style="darken"
            text="Confirm"
            onClick={async () => {
              await dailyInputQuery.refetch();
              hideModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};
