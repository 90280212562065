export class DailyInputSerializer {
  static deserialize(data) {
    return {
      id: data.id,
      averageDailyWeightLoss: {
        pounds: data.average_daily_weight_loss.pounds,
        ounces: data.average_daily_weight_loss.ounces
      },
      bowelMovementTimes: data.bowel_movement_times,
      date: data.date,
      daysInRound: {
        numberOfDays: data.days_in_round.number_of_days,
        totalDays: data.days_in_round.total_days
      },
      foodJournal: {
        lunch: {
          entries: data.food_journal_lunch,
          get formatted() {
            return this.entries
              ?.map(food => `${food.specify} ${food.ounces}oz`)
              .join(", ");
          }
        },
        dinner: {
          entries: data.food_journal_dinner,
          get formatted() {
            return this.entries
              ?.map(food => `${food.specify} ${food.ounces}oz`)
              .join(", ");
          }
        }
      },
      inputTime: data.input_time,
      passed: data.passed,
      programId: data.program_id,
      sleepHours: data.sleep_hours,
      sleepMinutes: data.sleep_minutes,
      triageColor: data.triage_color,
      triageStatus: {
        color: data.triage_status.color,
        isAuto: data.triage_status.is_auto
      },
      waterOunces: data.water_ounces,
      weight: { pounds: data.weight.pounds, ounces: data.weight.ounces },
      weightLossToDate: {
        pounds: data.weight_loss_to_date.pounds,
        ounces: data.weight_loss_to_date.ounces
      },
      weightOunces: data.weight_ounces,
      weightPounds: data.weight_pounds,
      note: data.note
    };
  }
}
