import React from "react";
import { Spinner } from "./Spinner";

export const FullScreenLoader = () => {
  return (
    <div
      className="w-full h-screen flex items-center justify-center fixed top-0 right-0 left-0 bottom-0 z-50"
      style={{ background: "rgb(0 0 0 / 50%)" }}
    >
      <Spinner width="100" height="100" color="#FFFF" />
    </div>
  );
};
