import React from "react";
import { FieldsByStep } from "./FieldsByStep";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { Wizard } from "../../../common/Wizard";

import { constants } from "../../../config/constants";
import { newCoachSchemasByStep } from "../../../validations/coach";
import { SELECT_PLACEHOLDERS } from "../../../utils/constants";
import { CoachesController } from "../../../networking/controllers/coaches-controller";

const NewCoachWizard = ({ setShowWizard }) => {
  const queryClient = useQueryClient();

  const initialValues = {
    coachType: "default",
    franchiseId: SELECT_PLACEHOLDERS.FRANCHISE
  };

  const mutation = useMutation({
    mutationFn: data => CoachesController.createCoach({ data }),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["coaches"] });
    },
    onSettled: () => {
      setShowWizard(false);
    }
  });

  const onSubmit = async data => {
    const coach = {
      name: data.coachName,
      email: data.coachEmail,
      coach_type: data.coachType,
      franchise_id: data.franchiseId
      // code: data.userPasswordCredential
    };

    try {
      await toast.promise(mutation.mutateAsync({ coach }), {
        loading: "Creating coach",
        success: "Coach was successfully created",
        error: "Couldn't create coach"
      });
    } catch (error) {}
  };

  const onNext = async (formMethods, currentStep, callback) => {
    try {
      const validStep = await formMethods.trigger();
      if (!validStep) return;

      if (currentStep === 1) {
        const coachEmail = formMethods.getValues().coachEmail;
        const splitedName = formMethods
          .getValues()
          .coachName.split(" ")
          .filter(str => str !== "");

        const firstName = splitedName[0];
        const lastName = splitedName[splitedName.length - 1];
        const code = `${firstName[0]}.${lastName}`.toUpperCase();

        formMethods.setValue("userEmailCredential", coachEmail);
        formMethods.setValue("userPasswordCredential", code);
      }

      callback();
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in client creation");
    }
  };

  return (
    <Wizard
      title="Create new coach"
      initialValues={initialValues}
      schemaByStep={newCoachSchemasByStep}
      totalSteps={constants.NEW_COACH_WIZARD.TOTAL_STEPS}
      onNext={onNext}
      onSubmit={onSubmit}
      setShowWizard={setShowWizard}
    >
      {currentStep => (
        <div className="w-full pb-10">
          <FieldsByStep step={currentStep} />
        </div>
      )}
    </Wizard>
  );
};

export default NewCoachWizard;
