import { api } from "../../config/httpRequest";
import { ProgramSerializer } from "../serializers/program-serializer";

export class ProgramsController {
  static async fetchPrograms({ where, query }) {
    const response = await api({
      method: "GET",
      endpoint: `/clients/${where.clientId}/programs`,
      query
    });

    const programs = await response.json;

    const deserializePrograms = programs.data.map(program =>
      ProgramSerializer.deserialize(program)
    );

    return deserializePrograms;
  }

  static async fetchProgram({ where }) {
    const response = await api({
      method: "GET",
      endpoint: `/clients/${where.clientId}/programs/${where.id}`
    });

    return await response.json;
  }

  static async updateProgram({ where, data }) {
    const response = await api({
      method: "PATCH",
      endpoint: `/clients/${where.clientId}/programs/${where.id}`,
      payload: { program: data }
    });

    return await response.json;
  }

  static async createProgram({ where, data }) {
    const response = await api({
      method: "POST",
      endpoint: `/clients/${where.clientId}/programs`,
      payload: { program: data }
    });

    return await response.json;
  }
}
