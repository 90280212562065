import React from "react";
import { useFormContext, get, useWatch } from "react-hook-form";

export const Select = ({
  containerStyles,
  children,
  name,
  label,
  disabled = false,
  hideRollbackButton = false,
  ...props
}) => {
  const { register, formState, setValue, control } = useFormContext();
  const currentValue = useWatch({
    control,
    name
  });
  const defaultValue = get(formState.defaultValues, name);
  const error = get(formState.errors, name);

  return (
    <div
      className={`${
        containerStyles ?? ""
      } relative w-full flex flex-col gap-2 ${disabled && "text-neutral-100"}`}
      style={{ height: "min-content" }}
    >
      {label && <label className="text-2xs font-medium">{label}</label>}
      <select
        className={`outline-none ${
          !disabled && "hover:border-neutral-900 cursor-pointer"
        } appearance-none w-full h-10 px-4 text-sm border border-neutral-100 rounded bg-white`}
        defaultValue={defaultValue}
        disabled={disabled}
        {...props}
        {...register(name)}
      >
        {children}
      </select>
      {error?.message && (
        <span className="text-2xs text-complementary-error">
          {error.message}
        </span>
      )}
      {currentValue !== defaultValue && !disabled && !hideRollbackButton && (
        <img
          className={`absolute bottom-0 right-8 m-auto cursor-pointer ${
            error?.message ? "top-0" : "top-6"
          }`}
          src="/x-black.svg"
          onClick={() => setValue(name, defaultValue)}
        />
      )}
      {!disabled && (
        <img
          className={`absolute bottom-0 right-2 m-auto cursor-pointer ${
            error?.message ? "top-0" : "top-6"
          }`}
          src="/select-down-arrow.svg"
        />
      )}
    </div>
  );
};
