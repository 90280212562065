import { api } from "../../config/httpRequest";
import { ClientSerializer } from "../serializers/client-serializer";

export class ClientsController {
  static async fetchClients(query) {
    const response = await api({
      method: "GET",
      endpoint: `/clients`,
      query
    });

    const clients = await response.json;

    const deserializeClients = clients.data.map(client =>
      ClientSerializer.deserialize(client)
    );

    return deserializeClients;
  }

  static async fetchClient(id) {
    const response = await api({
      method: "GET",
      endpoint: `/clients/${id}`
    });

    const client = await response.json;

    const deserializeClient = ClientSerializer.deserialize(client.data);

    return deserializeClient;
  }

  static async searchClient(query) {
    const response = await api({
      method: "GET",
      endpoint: `/fuzzy-search`,
      query: {
        ...query,
        exact: true
      }
    });

    const client = await response.json;

    return await client;
  }

  static async fuzzySearch(query) {
    const response = await api({
      method: "GET",
      endpoint: `/fuzzy-search`,
      query: query
    });

    const clients = await response.json;

    return await clients.data;
  }

  static async updateClient({ where, data }) {
    const response = await api({
      method: "PATCH",
      endpoint: `/clients/${where.id}`,
      payload: {
        client: data
      }
    });

    return await response.json;
  }

  static async createClient({ data: { client, program } }) {
    const response = await api({
      method: "POST",
      endpoint: `/clients`,
      payload: {
        client,
        program
      }
    });

    return await response.json;
  }
}
