import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

export function NoteWithSwitch({
  maxCharacters,
  onSubmit,
  placeholder,
  initialValue = "",
  resize = false
}) {
  const textareaRef = useRef(null);
  const [editable, setEditable] = useState(false);
  const [note, setNote] = useState(initialValue);

  const handleOnChange = e => {
    const element = e.target;
    setNote(element.value);
  };

  const handleOnSubmit = async () => {
    setEditable(prev => !prev);

    if (editable) await onSubmit(note);
  };

  useEffect(() => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.classList.add("flex-grow");
    const scrollHeight = textareaRef.current.scrollHeight;

    if (resize && scrollHeight > 0) {
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [note]);

  useEffect(() => {
    setNote(initialValue);
  }, [initialValue]);

  return (
    <div className={`p-4 pb-2 relative h-full flex flex-col flex-grow`}>
      <textarea
        ref={textareaRef}
        maxLength={maxCharacters}
        className="w-full outline-none bg-white resize-none h-full flex-grow"
        value={note}
        onChange={handleOnChange}
        placeholder={editable ? placeholder : "Click edit to add your notes"}
        spellCheck={false}
        disabled={!editable}
      />
      <button
        type="button"
        className={`absolute px-4 -top-1 right-0 text-neutral-900 text-xs font-monts font-semibold cursor-pointer text-c ${
          editable && "text-complementary-success"
        }`}
        onClick={handleOnSubmit}
      >
        {editable ? "Save" : "Edit"}
      </button>

      {editable && (
        <span className="text-right">
          {note.length} / {maxCharacters}
        </span>
      )}
    </div>
  );
}
