import React from "react";

export const TriageStatusModal = ({
  client,
  onHide,
  showModal,
  updateTriageStatus
}) => {
  const triages = ["red", "green", "yellow"];

  return (
    showModal && (
      <>
        <div
          className="z-10 w-screen h-screen fixed top-0 bottom-0 left-0 cursor-auto"
          onClick={e => {
            e.stopPropagation();
            onHide();
          }}
        ></div>
        <div
          className="mt-2 rounded-md shadow-lg focus:outline-none bg-white absolute top-10 right-8 z-20"
          style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)" }}
        >
          <div className="py-1">
            {triages.map(triage => {
              const style = {
                green: "bg-complementary-success",
                yellow: "bg-complementary-warning",
                red: "bg-complementary-error"
              }[triage];

              return (
                <button
                  key={`${client.id}-${triage}`}
                  className="text-neutral-500 block w-full text-left px-4 py-2 text-sm hover:bg-neutral-20 hover:font-medium"
                  onClick={() => {
                    updateTriageStatus(triage);
                  }}
                >
                  <div
                    style={{
                      width: "61px",
                      height: "16px",
                      borderRadius: "100px"
                    }}
                    className={`mx-auto my-auto ${style}`}
                  ></div>
                </button>
              );
            })}
          </div>
        </div>
      </>
    )
  );
};
