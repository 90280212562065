import React from "react";

export default function Pagination({ totalPages, currentPage, onChangePage }) {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="w-full flex justify-center items-center mt-6">
      <svg
        className={`${currentPage !== 1 ? "cursor-pointer" : ""}`}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          if (currentPage !== 1) {
            onChangePage(currentPage - 1);
          }
        }}
      >
        <path
          d="M19.2188 22.8125L19.8438 22.2188C19.9688 22.0625 19.9688 21.8125 19.8438 21.6875L14.1875 16L19.8438 10.3438C19.9688 10.2188 19.9688 9.96875 19.8438 9.8125L19.2188 9.21875C19.0625 9.0625 18.8438 9.0625 18.6875 9.21875L12.125 15.75C12 15.9062 12 16.125 12.125 16.2812L18.6875 22.8125C18.8438 22.9688 19.0625 22.9688 19.2188 22.8125Z"
          fill={`${currentPage === 1 ? "#BEBFBF" : "#25282A"}`}
        />
      </svg>

      {pages.map((page, index) => {
        const amountButtonsShown =
          currentPage === 1 || currentPage === totalPages ? 3 : 2;
        return (
          <React.Fragment key={index}>
            {page - currentPage === amountButtonsShown ||
            page - currentPage === -amountButtonsShown ? (
              <span
                className={`w-6 h-6 text-base text-center ${
                  currentPage === page ? "text-neutral-500" : "text-neutral-100"
                }`}
                key={index}
              >
                ...
              </span>
            ) : (
              <></>
            )}
            {page - currentPage < amountButtonsShown &&
              page - currentPage > -amountButtonsShown && (
                <span
                  className={`w-6 h-6 text-base text-center cursor-pointer ${
                    currentPage === page
                      ? "text-neutral-500"
                      : "text-neutral-100"
                  }`}
                  key={index}
                  onClick={() => onChangePage(page)}
                >
                  {page}
                </span>
              )}
          </React.Fragment>
        );
      })}

      <svg
        className={`${currentPage !== pages.length ? "cursor-pointer" : ""}`}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          if (currentPage !== pages.length) {
            onChangePage(currentPage + 1);
          }
        }}
      >
        <path
          d="M12.75 9.21875L12.125 9.8125C12 9.96875 12 10.2188 12.125 10.3438L17.7812 16L12.125 21.6875C12 21.8125 12 22.0625 12.125 22.2188L12.75 22.8125C12.9062 22.9688 13.125 22.9688 13.2812 22.8125L19.8438 16.2812C19.9688 16.125 19.9688 15.9062 19.8438 15.75L13.2812 9.21875C13.125 9.0625 12.9062 9.0625 12.75 9.21875Z"
          fill={`${currentPage === pages.length ? "#BEBFBF" : "#25282A"}`}
        />
      </svg>
    </div>
  );
}
