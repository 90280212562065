import * as yup from "yup";
import {
  FULL_NAME_REGEX,
  EMAIL_REGEX,
  SELECT_PLACEHOLDERS
} from "../utils/constants";
import { COACH_TYPES } from "../config/constants";
import { CoachesController } from "../networking/controllers/coaches-controller";

const coachInformationSchema = yup.object({
  coachName: yup
    .string()
    .required("Can't be empty")
    .matches(FULL_NAME_REGEX, "First and Last Name Required"),
  coachEmail: yup
    .string()
    .required("Can't be empty")
    .matches(EMAIL_REGEX, "Invalid Email")
    .when("$dirtyFields.coachEmail", {
      is: true,
      then: schema =>
        schema.test("uniqueEmail", "Email already in use", async value => {
          const coach = await CoachesController.searchCoach({
            email: value,
            throwNotFound: false
          });
          return !coach;
        })
    }),
  coachType: yup
    .string()
    .required("Can't be empty")
    .notOneOf([SELECT_PLACEHOLDERS.COACH_TYPE], "You must select a coach type")
    .oneOf(
      Object.entries(COACH_TYPES).map(([key, value]) => value),
      "Invalid coach type"
    ),
  franchiseId: yup
    .string()
    .required("Can't be empty")
    .notOneOf([SELECT_PLACEHOLDERS.FRANCHISE], "You must select a franchise")
});

const coachInformationCredentials = yup.object({
  userEmailCredential: yup.string().required("Can't be empty"),
  userPasswordCredential: yup.string().required("Can't be empty")
});

export const newCoachSchemasByStep = {
  1: coachInformationSchema,
  2: coachInformationCredentials
};
