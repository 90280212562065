import { useQuery } from "@tanstack/react-query";
import { CoachesController } from "../../../networking/controllers/coaches-controller";

export const useCurrentCoach = () => {
  const { data: coach } = useQuery({
    queryKey: ["currentCoach"],
    queryFn: CoachesController.fetchCurrentCoach
  });

  return coach;
};
