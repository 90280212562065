import React from "react";
import { TriageStatusRow } from "./TriageStatusRow";
import { Form } from "../../common/Form";
import { Field } from "../../common/Field";
import { Button } from "../../common/Button";
import { dailyEntriesSchema } from "../../validations/clients";

export function BowelMovementRow({
  setModalContent,
  setShowModal,
  program,
  row,
  onSubmitRow
}) {
  const onSubmit = async data => {
    await onSubmitRow({
      row,
      data: {
        bowel_movement_times: data.bowelMovement
      },
      message: {
        loading: "Updating Bowel Movement",
        success: "Bowel Movement was successfully updated",
        error: "Couldn't update Bowel Movement"
      }
    });

    setShowModal(false);
    setModalContent(null);
  };

  const showModal = () => {
    setModalContent(() => {
      return (
        <div className="w-1/3 bg-white p-8 rounded-lg">
          <p className="mb-4">Bowel Movement</p>
          <Form
            initialValues={{
              bowelMovement: row.bowelMovementTimes
            }}
            schema={dailyEntriesSchema.pick(["bowelMovement"])}
            onSubmit={onSubmit}
          >
            <Field
              className="text-center"
              type="number"
              name="bowelMovement"
              label="Times"
              placeholder="times"
            />
            <div className="flex w-full gap-8">
              <Button
                type="button"
                style="darken"
                text="Discard"
                onClick={() => {
                  setShowModal(false);
                  setModalContent(null);
                }}
              />
              <Button type="submit" style="darken" text="Confirm" />
            </div>
          </Form>
        </div>
      );
    });

    setShowModal(true);
  };

  return (
    <>
      <td className="border-r-0.5 p-4">{row.date}</td>
      <td className="border-r-0.5 p-4 relative">
        <p>{row.bowelMovementTimes} times</p>
        {program.status === "ongoing" && (
          <button
            className="m-2 absolute top-0 right-0 flex items-center gap-2"
            type="button"
            onClick={showModal}
          >
            <img src="/edit.svg" width={15} />
          </button>
        )}
      </td>
      <td className="border-r-0.5 p-4">
        {row.daysInRound.numberOfDays} of {row.daysInRound.totalDays}
      </td>
      <TriageStatusRow row={row} />
    </>
  );
}
