import { useQuery } from "@tanstack/react-query";
import { ClientsController } from "../../../networking/controllers/clients-controller";

export const useClients = params => {
  const { data: clients } = useQuery({
    queryKey: ["clients", params || "all"],
    queryFn: () => ClientsController.fetchClients(params)
  });

  return clients;
};
