import React from "react";
import { useFormContext, get } from "react-hook-form";

export const Field = ({
  type = "text",
  name,
  label,
  containerStyles,
  className,
  disabled,
  ...rest
}) => {
  const { register, formState } = useFormContext();
  const error = get(formState.errors, name);

  return (
    <div
      className={`${
        !containerStyles?.includes("mb") && "mb-6"
      } ${containerStyles} w-full flex flex-col gap-2 ${
        disabled && "text-neutral-100"
      }`}
    >
      {label && <label className="text-2xs font-medium">{label}</label>}
      <input
        className={`w-full h-10 px-4 text-sm border border-neutral-100 rounded bg-white ${className} outline-none ${
          !disabled && "hover:border-neutral-900"
        }`}
        type={type}
        name={name}
        disabled={disabled}
        {...rest}
        {...register(name)}
      />
      {error?.message && (
        <span className="text-2xs text-complementary-error">
          {error.message}
        </span>
      )}
    </div>
  );
};
