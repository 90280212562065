import React from "react";
import { useCoaches } from "../../coaches/hooks/queries/useCoach";
import { Form } from "../../common/Form";
import { Field } from "../../common/Field";
import { searchClientSchema } from "../../validations/clients";
import { Select } from "../../common/Select";
import { PhoneFiled } from "../../common/PhoneField";

const SearchClientForm = ({ setClientsQuery }) => {
  const coaches = useCoaches();
  const clientStatus = ["Active", "Inactive"];
  const selectCoachPlaceholder = "Search Health Coach";

  const initialValues = {
    status: "Active",
    coach: selectCoachPlaceholder
  };

  const onSubmit = data => {
    setClientsQuery({
      name: data.name || "",
      phone: data.phone || "",
      email: data.email || "",
      coach: data.coach === selectCoachPlaceholder ? "" : data.coach,
      status: data.status
    });
  };

  return (
    <div className="relative w-1/3 min-w-96 px-16 flex flex-col items-center bg-white overflow-y-auto">
      <div className="mt-10 mb-10">
        <span className="font-semibold text-xl">Search client</span>
      </div>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        schema={searchClientSchema}
        onSubmitButton={({ formMethods }) => (
          <button
            type="submit"
            className="w-full transition duration-200 ease-in-out px-6 h-10 rounded bg-primary-300 text-white cursor-pointer hover:bg-primary-800"
          >
            SEARCH
          </button>
        )}
        onCancelButton={({ formMethods }) => (
          <button
            type="button"
            className="mr-4 w-full transition duration-200 ease-in-out h-10 rounded border border-primary-300 text-primary-300 cursor-pointer hover:bg-primary-800 hover:text-white hover:border-primary-800"
            onClick={() => {
              formMethods.reset();
              setClientsQuery("");
            }}
          >
            <span>CLEAR FILTERS</span>
          </button>
        )}
        buttonsContainer={({ children }) => {
          return (
            <div
              className="absolute w-full flex justify-around items-center bottom-0 left-0 py-8 px-16 bg-white"
              style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)" }}
            >
              {children}
            </div>
          );
        }}
      >
        <Field name="name" label="Full Name" />
        <PhoneFiled name="phone" label="Phone" initialCountry={false} />
        <Field name="email" label="Email" />
        <div className="flex flex-col gap-6">
          <Select name="status" label="Client status">
            {clientStatus.map((status, index) => (
              <option value={status} key={index}>
                {status}
              </option>
            ))}
          </Select>
          <Select name="coach" label="Assigned Health Coach">
            <option value={selectCoachPlaceholder} disabled hidden>
              {selectCoachPlaceholder}
            </option>
            {coaches.map(coach => (
              <option value={coach.id} key={coach.id}>
                {coach.name}
              </option>
            ))}
          </Select>
        </div>
      </Form>
    </div>
  );
};

export default SearchClientForm;
