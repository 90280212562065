import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

export function Note({
  maxCharacters,
  onSubmit,
  disabled = false,
  placeholder,
  initialValue = "",
  resize = false
}) {
  const textareaRef = useRef(null);
  const [note, setNote] = useState(initialValue);

  const handleOnChange = e => {
    const element = e.target;

    if (resize) {
      element.style.height = `${Math.min(
        element.scrollHeight,
        maxCharacters
      )}px`;
    }

    setNote(element.value);
  };

  const handleOnCancel = () => {
    setNote("");
  };

  const handleOnSubmit = async () => {
    await onSubmit(note);
  };

  useEffect(() => {
    if (resize) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [note]);

  useEffect(() => {
    setNote(initialValue);
  }, [initialValue]);

  return (
    <div className="px-4 pt-8 pb-2 relative h-full">
      <textarea
        ref={textareaRef}
        maxLength={maxCharacters}
        className="w-full outline-none bg-white resize-none h-full"
        value={note}
        onChange={handleOnChange}
        placeholder={placeholder}
        spellCheck={false}
        disabled={disabled}
      />
      {!disabled && (
        <div className="w-full px-4 pt-2 absolute top-0 right-0 flex items-center justify-between">
          <div className="flex flex-grow items-center gap-2 justify-end">
            <span>
              {note.length} / {maxCharacters}
            </span>
            <button onClick={handleOnCancel} type="button">
              <img src="/x-black.svg" />
            </button>
            <button onClick={handleOnSubmit} type="button">
              <img src="/done.svg" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
