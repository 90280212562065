import React, { createContext, useContext, useState } from "react";

const initialState = {
  client: null,
  program: null,
  editable: false
};

export const ShowClientContext = createContext(initialState);

export const ShowClientProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <ShowClientContext.Provider value={{ state, setState }}>
      {children}
    </ShowClientContext.Provider>
  );
};

export const useShowClient = () => useContext(ShowClientContext);
