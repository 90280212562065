const DAYS_TO_UPDATE = 7;
const PROGRAM_MAX_PHASE = 4;
const PROGRAM_MIN_PHASE = 1;
const INITIAL_STEP_WIZARD = 1;

const NEW_CLIENT_WIZARD = {
  INITIAL_STEP: INITIAL_STEP_WIZARD,
  TOTAL_STEPS: 3
};

const NEW_COACH_WIZARD = {
  INITIAL_STEP: INITIAL_STEP_WIZARD,
  TOTAL_STEPS: 2
};

export const PROGRAM_STATUSES = {
  UPCOMING: "upcoming",
  ONGOING: "ongoing",
  FINISHED: "finished"
};

export const COACH_TYPES = {
  ADMIN: "admin",
  "FRONT DESK": "front_desk",
  DEFAULT: "default"
};

export const constants = {
  DAYS_TO_UPDATE,
  PROGRAM_MAX_PHASE,
  PROGRAM_MIN_PHASE,
  NEW_CLIENT_WIZARD,
  COACH_TYPES,
  INITIAL_STEP_WIZARD,
  NEW_COACH_WIZARD
};
