import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useFormContext, get, Controller } from "react-hook-form";

export const PhoneFiled = ({
  name,
  label,
  initialCountry = true,
  disabled = false,
  defaultCountry = "us",
  containerStyles
}) => {
  const { formState, control } = useFormContext();
  const error = get(formState.errors, name);

  return (
    <div
      className={`mb-6 ${containerStyles} w-full flex flex-col gap-2 ${
        disabled && "text-neutral-100"
      }`}
    >
      {label && <label className="text-2xs font-medium">{label}</label>}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <PhoneInput
            disabled={disabled}
            country={initialCountry && defaultCountry}
            containerClass="w-full"
            inputStyle={{ width: "100%", height: "40px" }}
            {...field}
          />
        )}
      />

      {error?.message && (
        <span className="text-2xs text-complementary-error">
          {error.message}
        </span>
      )}
    </div>
  );
};
