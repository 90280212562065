import { useQuery } from "@tanstack/react-query";
import { ProgramsController } from "../../../networking/controllers/programs-controller";

export const useClientPrograms = ({ where, query }) => {
  const { data: programs } = useQuery({
    queryKey: ["client-programs", where.clientId],
    queryFn: () => ProgramsController.fetchPrograms({ where, query })
  });

  return programs;
};
