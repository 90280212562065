import { useMutation } from "@tanstack/react-query";
import { FoodJournalsController } from "../../../networking/controllers/food-journals-controller";

export const useFoodJournalMutation = () => {
  return {
    create: useMutation(
      ({ where, data }) => FoodJournalsController.create({ where, data }),
      {}
    ),
    update: useMutation(
      ({ where, data }) => FoodJournalsController.update({ where, data }),
      {}
    ),
    delete: useMutation(
      ({ where }) => FoodJournalsController.delete({ where }),
      {}
    )
  };
};
