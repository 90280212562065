import { api } from "../../config/httpRequest";

export class FoodJournalsController {
  static async create({ where, data }) {
    const response = await api({
      method: "POST",
      endpoint: `/clients/${where.clientId}/programs/${where.programId}/daily_inputs/${where.dailyInputId}/food_journals`,
      payload: { food_journal: data }
    });

    return response;
  }

  static async update({ where, data }) {
    const response = await api({
      method: "PATCH",
      endpoint: `/clients/${where.clientId}/programs/${where.programId}/daily_inputs/${where.dailyInputId}/food_journals/${where.id}`,
      payload: { food_journal: data }
    });

    return response;
  }

  static async delete({ where }) {
    const response = await api({
      method: "DELETE",
      endpoint: `/clients/${where.clientId}/programs/${where.programId}/daily_inputs/${where.dailyInputId}/food_journals/${where.id}`
    });

    return response;
  }
}
