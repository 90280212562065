import React from "react";

export const StepCounter = ({ currentStep, totalSteps }) => {
  return Array.from({ length: totalSteps }, (_, index) => {
    return (
      <div
        key={`bullet-setp-${index}`}
        className={
          index + 1 !== totalSteps
            ? `w-16 h-px ${
                currentStep >= index + 2 ? "bg-primary-300" : "bg-neutral-20"
              } flex justify-start items-center`
            : undefined
        }
      >
        <div
          className={`${
            currentStep >= index + 1 ? "bg-primary-300" : "bg-neutral-20"
          } w-3 h-3 rounded-full`}
        ></div>
      </div>
    );
  });
};
